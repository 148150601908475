.content {
  display: grid;
  gap: 10px;
}

.headingWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question {
  padding: 12px 0;
  text-align: center;
  background-color: #007bff;
  border-radius: 4px;
}
