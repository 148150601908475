.wrapper {
  display: grid;
  gap: 20px;
  width: 80%;
  margin: 0 auto;
  padding: 20px 40px;
}

.result {
  font-style: italic;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.item {
  padding: 12px 16px;
  border-bottom: 1px solid #fff;
}

.item:hover,
.item:focus,
.item:active {
  background: rgba(255, 255, 255, 0.2);
  transition: background 0.3s ease-in-out;
}
