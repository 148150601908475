.wrapper {
  display: grid;
  gap: 40px;
  padding: 16px 40px;
}

.content {
  display: grid;
  gap: 24px;
}
