.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;

  display: flex;
  width: 100%;
  min-height: 150px;
  height: 100%;

  background-color: hsla(0, 0%, 0%, 0.9);
}

.content {
  margin: auto;
  color: white;
}
