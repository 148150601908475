.wrapper {
  display: grid;
  gap: 60px;
  padding: 40px 0;
  align-items: center;
  text-align: center;
}

.heading {
  font-weight: normal;
  font-size: 1.8rem;
  font-style: italic;
}

.message {
  font-size: 3.2rem;
  color: #eee;
  text-shadow: 3px 3px #5041b2;
}

.link {
  display: inline-block;
  margin: 0 auto;
  width: 120px;
  height: 120px;
  color: #fff;
  font-size: 1.4rem;
  background-color: hsl(213, 92%, 46%);
  border-radius: 50%;
  border: 4px solid hsl(213, 92%, 46%);
  box-shadow: 0 0 6px 6px #2884f6;
  cursor: pointer;
}
.link:hover {
  background-color: hsl(213, 92%, 41%);
}
