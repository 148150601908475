.wrapper {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 80%;
  max-width: 350px;
  padding: 100px 30px 40px;
  background: #fff;
  transform: translateX(0px);
  transition: transform 0.22s ease-in;
  z-index: 90;
}

.wrapper.close {
  transform: translateX(350px);
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.listItem {
  border-bottom: 2px solid #7969e6;
}

.link {
  display: inline-block;
  width: 100%;
  padding: 12px 16px;
  color: #363d54;
  font-size: 1.4rem;
  text-decoration: none;
  background-color: #fff;
  transform: translateX(0px);
  transition: transform 0.2s ease-in;
}

.link:hover,
.link:focus,
.link:active {
  transform: translateX(10px);
}
