.content {
  display: grid;
  gap: 10px;
}

.list {
  display: grid;
  gap: 10px;
  list-style: none;
  margin: 0;
  padding: 0;
}
