.heading {
  font-size: 1.8rem;
  text-align: center;
  font-style: italic;
}

.content {
  display: grid;
  gap: 32px;
  width: 80%;
  margin: 0 auto;
  padding: 40px;
  color: #fff;
  border: 3px solid #2884f6;
  border-radius: 8px;
}
