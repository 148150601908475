.item {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 12px 16px;
  cursor: pointer;
}

.item:hover,
.item:focus,
.item:active {
  background: rgba(255, 255, 255, 0.2);
  transition: background 0.3s ease-in-out;
}

.right:hover,
.right:focus,
.right:active {
  background: rgba(161, 240, 69, 0.7);
}

.wrong:hover,
.wrong:focus,
.wrong:active {
  background: rgba(240, 87, 108, 0.7);
}
