.wrapper {
  display: grid;
  gap: 28px;
  place-items: center;
  justify-content: center;
  margin: auto;
}

.mr0 {
  margin-right: 0;
}
